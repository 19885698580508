<template>
	<div>
		<div class="sub-tit"><p>인터뷰 요청</p></div>

		<!-- 관련내용없는경우 -->
		<div v-if="pageInfo.totalRecordCount == 0" class="view int_prj none">
			<p>인터뷰가 없습니다!</p>
		</div>		

		<!-- 프로젝트 인터뷰 요청 new -->
		<div v-for="(interview, index) in projectList" :key="interview.interviewReqSeq" class="itv-box">
			<div class="no">
				{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}
			</div>
			<div class="view">
				<div class="req">
					<img class="itv" src="/images/tec/prj/interview.png" alt="인터뷰"/>
					<div class="itv-outline">
						<div class="tit">프로젝트 인터뷰 요청
							<span v-if="(interview.interviewStatusCd == '01' || interview.interviewStatusCd == '02') && interview.newYn == 'Y'">new</span>
							<span v-if="interview.interviewStatusCd != '01' && interview.interviewStatusCd != '02'" class="cp">인터뷰 완료</span>
						</div>
						<div class="list-view">
							<div class="label prj">프로젝트명</div><div class="list" @click="clickProjectInfo(interview.projectSeq)">{{interview.projectNm}}</div>
						</div>
						<div class="list-view">
							<div class="label ivl04">수행사</div>
							<div class="list" style="display: inline-flex;">
								{{interview.corpNm}}
								<div class="loca">
									<img src="/images/location.png" alt="위치"/>{{interview.projectLocCont}}
								</div>
							</div>
						</div>
						<div class="list-view">
							<div class="label ivl04">담당자</div><div class="list">{{interview.corpMberNm}}</div>
						</div>
						<div class="list-view">
							<div class="label ivl05">개발기간</div><div class="list">{{interview.projectStartEndYyyymmdd}}</div>
						</div>
						<div class="list-view">
							<div class="label ivl03">개요</div><div class="list text">{{interview.projectDtlDesc}}</div>
						</div>
					</div>
				</div>

				<!-- 면접요청 -->
				<div v-if="interview.interviewStatusCd == '01'" class="noti">
					<img src="/images/tec/prj/date.png" alt="요청일"/>인터뷰 요청전
					<div class="date" style="font-family: NanumSquare_acR; font-size: 15px;">인터뷰 요청 전입니다. <a href="javascript:void(0)" @click="reqInterview(interview)"><img src="/images/admin/itv_icon_04.png"/></a></div>
					<!-- <div class="zoom">
						카카오톡으로 인터뷰 참가 링크를 보내드립니다!
						<img src="/images/tec/prj/zoom.png" alt="줌이미지"/><a href="https://zoom.us/" target="_blank"><span>줌 시작하기 &#9654;</span></a>
					</div> -->
				</div>
				
				<!-- 면접중 -->
				<div v-if="interview.interviewStatusCd == '02'" class="noti">
					<img src="/images/tec/prj/date.png" alt="요청일"/>인터뷰 요청일
					<div class="date">{{interview.interviewYyyymmdd}} / {{interview.interviewDay}} {{interview.interviewHhmm}}</div>
					<!-- <div class="zoom">
						카카오톡으로 인터뷰 참가 링크를 보내드립니다!
						<img src="/images/tec/prj/zoom.png" alt="줌이미지"/><a href="https://zoom.us/" target="_blank"><span>줌 시작하기 &#9654;</span></a>
					</div> -->
				</div>

				<!-- 합격 -->
				<div v-if="interview.interviewStatusCd == '04'" class="noti">
					<span class="tit">프로젝트 인터뷰 결과</span>
					<img class="pass" src="/images/tec/prj/pass.png" alt="합격이미지"/>
					<div class="field">프로젝트 지원분야 [{{interview.workCont}}]</div>
					<span class="notice">프로젝트 참여관련 세부사항은 개별적으로 연락드립니다!</span>
				</div>

				<!-- 불합격 -->
				<div v-if="interview.interviewStatusCd == '03' || interview.interviewStatusCd == '99'" class="noti">
					<span class="tit">프로젝트 인터뷰 결과</span>
					<span class="guide">죄송합니다!<br/>미승인 되었습니다.</span>
					<div class="field">프로젝트 지원분야 [{{interview.workCont}}]</div>
					<span class="notice">프로젝트 참여관련 세부사항은 개별적으로 연락드립니다!</span>
				</div>

			</div>
		</div>

		<!-- 페이징부분 -->
		<paging-comp :pageInfo="pageInfo" @page-click="goPage"/>

	</div>

</template>

<script>

/**
 * 기술인재의 인터뷰요청 목록
 */
import PagingComp from '@/components/PagingComp.vue';

export default {

	components : {
		PagingComp
	},

	props: {
        tecMberSeq : String  
    },

	data() {
		return {
			input: {
				pageIndex : '1',
				tecMberSeq : 0,
				orderGubun : '1',	// 1:최신요청순, 2:최신등록 프로젝트 순
			}, // 검색조건	
			
			data : {},

			projectList : [],
			pageInfo : {},
		};
	},
	beforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');

		if(this.$route.query.tecMberSeq != undefined && this.$route.query.tecMberSeq != '') {
			this.input.tecMberSeq = this.$route.query.tecMberSeq;
		} else {
			this.input.tecMberSeq = this.tecMberSeq;
		}
	},
	mounted() {
		// console.log('mounted');

		this.getList();
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		
		getList() {
			
			this.$.httpPost('/api/prj/adm/getTecMberInterviewList', this.input)
				.then(res => {
					// console.log(res.data);
					this.projectList 	= res.data.list;
					this.pageInfo 		= res.data.pageInfo;

					this.$emit('searchEnd', res.data);
				}).catch(this.$.httpErrorCommon);
		},

		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},

		reqInterview(interview){
			//console.log('reqInterview', interview);
			var mberSeq = this.input.tecMberSeq;
			var interviewReqSeq = interview.interviewReqSeq;
			var projectSeq = interview.projectSeq;
			var projectRecrFieldSeq = interview.projectRecrFieldSeq;
			this.$.popup('/adm/prj/PRJ911P01', {mberSeq, interviewReqSeq, projectSeq, projectRecrFieldSeq})
				.then(res => {
					if(res) this.getList();
				})
		},

		clickProjectInfo(projectSeq) {
			var params = {
				caller : {
					name: this.$route.name,
					params: this.input
				}
			}

			this.$emit('move', {name: 'PRJ904M02', query : {tecMberSeq : this.input.tecMberSeq, projectSeq : projectSeq}, params });
			//this.$router.push({name: 'PRJ901M02', query : {tecMberSeq : this.input.tecMberSeq, projectSeq : projectSeq}, params });
		},
	},
};
</script>
